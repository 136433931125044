export async function getCredential(id: string) {
  const response = await fetch(`https://passkey-kv.j0nl1.workers.dev/?id=${id}`);
  const key = await response.text();
  return new Uint8Array(JSON.parse(atob(key)));
}

export async function saveCredential(id: string, pbKey: Uint8Array) {
  const key = JSON.stringify([...pbKey]);
  await fetch(`https://passkey-kv.j0nl1.workers.dev/?id=${id}&key=${btoa(key)}`);
}
