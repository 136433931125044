import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import { convertAsn1toRaw, createWebauthnCredential, requestWebauthnSignature } from "./webauthn";
import { verify } from "wasm-passkey";
import "../public/index.css";
import { getCredential, saveCredential } from "./storage";

const App = () => {
  const [error, setError] = useState<string | null>(null);
  const [credentialName, setCredentialName] = useState<string>("");
  const [verification, setVerification] = useState<boolean>(false);

  return (
    <div className="container">
      <div className="input-group">
        <input
          type="email"
          className="input"
          placeholder="Name"
          autoComplete="off"
          value={credentialName}
          onChange={({ target }) => setCredentialName(target.value)}
        />
        <input
          className="button--submit"
          value="Create Passkey"
          type="button"
          onClick={() =>
            createCredential(credentialName)
              .then(() => setCredentialName(""))
              .catch((e) => setError(e.message))
          }
        />
      </div>

      {error && <div>{error}</div>}
      <button className="button--submit button--submit--full" onClick={() => requestAndVerify().then(setVerification)} type="button">
        Verify
      </button>
      {verification ? (
        <div>
          <div>Credential: {verification ? "Verified" : "Not verified"}</div>
        </div>
      ) : null}
    </div>
  );
};

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

const createCredential = async (credentialName: string) => {
  const { publicKey, id } = await createWebauthnCredential(credentialName);
  await saveCredential(id, publicKey);
};

const requestAndVerify = async () => {
  const challenge = crypto.getRandomValues(new Uint8Array(32));

  const { assertion, id: credentialId } = await requestWebauthnSignature(challenge);

  const pbKey = await getCredential(credentialId);

  const authenticatorData = new Uint8Array(assertion.authenticatorData);
  const clientDataJSON = new Uint8Array(assertion.clientDataJSON);
  const signature = new Uint8Array(assertion.signature);

  const verification = {
    browser: false,
    wasm: false,
  };

  try {
    // Verify in browser
    const digestClientJSON = new Uint8Array(await crypto.subtle.digest("SHA-256", assertion.clientDataJSON));

    const signedData = new Uint8Array(authenticatorData.length + digestClientJSON.length);
    signedData.set(authenticatorData);
    signedData.set(digestClientJSON, authenticatorData.length);

    const key = await crypto.subtle.importKey("raw", pbKey, { name: "ECDSA", namedCurve: "P-256" }, true, ["verify"]);

    const verified = await crypto.subtle.verify({ name: "ECDSA", hash: { name: "SHA-256" } }, key, convertAsn1toRaw(signature), signedData);

    console.log("Verification from browser: ", verified);
    // verification.browser = verified;
    return verified;
  } catch (err) {
    console.log(err);
  }

  /*  try {
    // Verify in WASM
    const { origin, crossOrigin } = JSON.parse(new TextDecoder().decode(clientDataJSON));
    const verified = verify(
      challenge,
      {
        origin,
        cross_origin: crossOrigin,
      },
      {
        pub_key: pbKey,
        signature: signature,
        authenticator_data: authenticatorData,
      }
    );
    console.log("Verification from wasm: ", verified);
    // verification.wasm = verified;
  } catch (err) {
    console.log(err);
  }
  return verification; */
};
